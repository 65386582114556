.root {
  background-color: white;
  display: flex;
  gap: 70px;
  place-content: center;
  padding:3em;
}

.heroTitle {
  color: rgb(41, 41, 41);
  font-size: 30px;
  margin: inherit;
  text-align: center;
  font-weight: 1em;
}
.description {
  color: #292929;
  font-size: 1.26rem;
  text-align: center;
  word-break: break-word;
}

.btnDiv {
  display: flex;
  place-content: center;
  color: black;
}

.textContent {
  display: flex;
  flex-direction: column;
  place-content: center;
}

.btn {
  font-size: 1.3em;
  width: 150px;
  padding: 9px 15px;
  background: #ffc41c;
  text-align: center;
}
.btn:hover {
  cursor: pointer;
  background: #eeb311;
}

.btn:active {
  background: #dab74e;
}

/* images */
.rightDiv {
  padding: 3% 3% 0px 0;
}
.img {
  box-shadow: 0px 2px 30px rgb(41 41 41 / 64%);
}

.img1 {
  position: absolute;
  top: 220px;
  right: 11%;
  z-index: 1;
}

/* divider */
.divider {
    background: #3380FF;
    height: 6px;
    width: 100%;
    position: relative;
}

.redBlock {
    background: #f33;
    height: 6px;
    width: 150px;
    position: absolute;
    left: 36px;
}
