.plainContainer {
  width: 100%;
  height: 450px;
  position: relative;
}

/* .homePageImage {
  background: linear-gradient(
        to right,
        rgba(0, 0, 0, 0.8) 0%,
        rgba(0, 0, 0, 0.2) 43%,
        rgba(0, 0, 0, 0.2) 72%,
        rgba(0, 0, 0, 0.8) 99%
      )
      0% 0% / cover,
    url(../images/remote-syna.JPG) center center no-repeat;
  height: 450px;
  width: 100%;
} */

.imageOverlay {
  position: absolute;
  top: 37%;
  left: 20%;
  right: 20%;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.overLaytext {
  height: 45px;
  font-family: 'Source Sans Pro';
  font-size: 22px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: rgb(255, 255, 255);
  animation-name: moveInLeft;
  animation-duration: 1s;
  animation-timing-function: ease-out;
}

.lowerSection {
  padding: 5% 14% 0%;
  background: #efefef;
  color: #000;
}

.componentsSection {
  display: flex;
  place-content: center space-between;
  gap: 15%;
  margin: 4% 0%;
  position: relative;
}

.subHeaderText {
  color: #3381ff;
  font-weight: 600;
}

.sectionTitle {
  font-size: 32px;
}

.sectionDescription {
  font-size: 20px;
}

img {
  border-radius: 12px;
}

.componentsTextSection {
  margin-top: 6%;
  display: grid;
  grid-template-rows: 25px 35px 65px 35px;
  gap: 12px;
}

.microfrontendsSection {
  display: flex;
  justify-content: space-between;
  align-content: center;
  gap: 10%;
  margin: 4% 0% 4% 0%;
  position: relative;
}

.microfrontendsTextSection {
  margin-top: 6%;
  display: grid;
  grid-template-rows: 25px 35px 92px 35px;
  gap: 12px;
}

.configurationsSection {
  display: flex;
  justify-content: space-between;
  align-content: center;
  gap: 20%;
  margin: 4% 0% 4% 0%;
  position: relative;
}

.configurationstextSection{
  margin-top: 6%;
  display: grid;
  grid-template-rows: 25px 35px 65px 45px;
  gap: 12px;
}


.btn {
  font-size: 1.3em;
  width: 150px;
  padding: 10px;
  background: #ffc41c;
  text-align: center;
}
.btn:hover {
  cursor: pointer;
  background: #eeb311;
}

.btn:active {
  background: #dab74e;
}

.compLayeredDiv {
  position: absolute;
  background: #ffc41c;
  height: 242px;
  width: 350px;
  top: 100px;
  right: 4rem;
}
.federationLayeredDiv {
  position: absolute;
  background: #e60505;
  height: 242px;
  width: 350px;
  top: 100px;
  left: 4rem;
}
.configLayeredDiv {
  position: absolute;
  background: #ffc41c;
  height: 293px;
  width: 350px;
  top: 100px;
  right: 1em;
}

.imgdivs {
  position: relative;
  z-index: 1;
  box-shadow: 0px 2px 30px rgb(41 41 41 / 64%);
}
.imgOrg {
  position: relative;
  z-index: 1;
  /* box-shadow: 0px 2px 30px rgb(41 41 41 / 64%); */
  border-radius: 12px;
}
/* divider */
.divider {
  background: #3380ff;
  height: 6px;
  width: 100%;
  position: relative;
}

.redBlock {
  background: #f33;
  height: 6px;
  width: 150px;
  position: absolute;
  left: 36px;
}
